body {
  font-family: 'Noto Sans CJK JP';
  background: #ffffff;
  color: #34395D;
}

@font-face {
  font-family: 'Noto Sans CJK JP';
  font-style: normal;
  font-weight: normal;
  src: local('Noto Sans CJK JP'),
    url('./fonts/NotoSansCJKjp-Regular.woff2') format('woff2'),
    url('./fonts/NotoSansCJKjp-Regular.woff') format('woff'),
    url('./fonts/NotoSansCJKjp-Regular.ttf') format('truetype'),
    url('./fonts/NotoSansCJKjp-Regular.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Noto Sans CJK JP';
  font-style: normal;
  font-weight: bold;
  src: local('Noto Sans CJK JP'),
    url('./fonts/NotoSansCJKjp-Bold.woff2') format('woff2'),
    url('./fonts/NotoSansCJKjp-Bold.woff') format('woff'),
    url('./fonts/NotoSansCJKjp-Bold.ttf') format('truetype'),
    url('./fonts/NotoSansCJKjp-Bold.eot') format('embedded-opentype');
}
